<template>
    <div class="form-box">
        <div class="form-box__tab" v-if="false">
            <span :class="{'active': tabShow == 'weixin'}" @click="tabChange('weixin')">微信快捷登录</span>
            <span :class="{'active': tabShow == 'phone'}" @click="tabChange('phone')">手机号登录</span>
        </div>
        <div class="form-box__weixin" v-show="tabShow == 'weixin'">
            <div class="qrcode-view" v-show="!qrcodeState.showBindPhone">
                <div class="qrcode-view__img">
                    <img class="img" v-if="qrcodeState.qrcode" :src="qrcodeState.qrcode" alt="第一星座">
                    <a-spin size="large" class="spin" v-if="qrcodeState.qrcodeLoading" />
                    <div class="overdue" v-if="qrcodeState.qrcodeOverdue">
                        <p>二维码已失效</p>
                        <a-button type="primary" @click="getQrcode">点击刷新</a-button>
                    </div>
                </div>
                <div class="qrcode-view__tip">微信扫码 <b>“关注公众号”</b> 快捷登录/注册</div>
            </div>
            <a-form-model v-show="qrcodeState.showBindPhone" class="qrcode-form" ref="qrcodeForm" :model="qrcodeFormField" :rules="qrcodeFormRules">
                <div class="qrcode-form__tip">为保障账户安全，请绑定手机号</div>
                <a-form-model-item prop="mobile">
                    <a-input
                        size="large"
                        type="text"
                        placeholder="手机号码"
                        v-model="qrcodeFormField.mobile"
                    >
                        <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item ref="captcha" prop="captcha" :autoLink="false">
                    <a-row :gutter="16">
                        <a-col class="gutter-row" :span="14">
                            <a-input
                                size="large"
                                type="text"
                                placeholder="短信验证码"
                                v-model="qrcodeFormField.captcha"
                            >
                                <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" />
                            </a-input>

                        </a-col>
                        <a-col class="gutter-row" :span="10">
                            <a-button
                                class="qrcode-form__getcode"
                                tabindex="-1"
                                :disabled="qrcodeState.smsSendBtn"
                                @click.stop.prevent="qrcodeCaptchaGet"
                                v-text="(!qrcodeState.smsSendBtn && '获取验证码') || qrcodeState.time + ' s'"
                            ></a-button>
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <a-form-model-item>
                    <a-button size="large" type="primary" class="qrcode-form__btnsubmit" :loading="qrcodeState.loginBtn" :disabled="qrcodeState.loginBtn" @click="qrcodeSubmit">绑定登录</a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
        <div class="form-box__phone" v-show="tabShow == 'phone'">
            <a-form-model ref="phoneForm" :model="phoneFormField" :rules="phoneFormRules">
                <a-form-model-item prop="mobile">
                    <a-input
                        size="large"
                        type="text"
                        placeholder="手机号码"
                        v-model="phoneFormField.mobile"
                    >
                        <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item ref="captcha" prop="captcha" :autoLink="false">
                    <a-row :gutter="16">
                        <a-col class="gutter-row" :span="14">
                            <a-input
                                size="large"
                                type="text"
                                placeholder="短信验证码"
                                v-model="phoneFormField.captcha"
                            >
                                <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" />
                            </a-input>

                        </a-col>
                        <a-col class="gutter-row" :span="10">
                            <a-button
                                class="btn-getcode"
                                tabindex="-1"
                                :disabled="phoneState.smsSendBtn"
                                @click.stop.prevent="phoneCaptchaGet"
                                v-text="(!phoneState.smsSendBtn && '获取验证码') || phoneState.time + ' s'"
                            ></a-button>
                        </a-col>
                    </a-row>
                </a-form-model-item>
                <a-form-model-item>
                    <a-button size="large" type="primary" class="btn-submit" :loading="phoneState.loginBtn" :disabled="phoneState.loginBtn" @click="phoneSubmit">登录/注册</a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabShow: 'phone',
            registerPromoter: '', // 分享注册发起人
            // 二维码登录相关
            qrcodeFormField: {
                mobile: '',
                captcha: '',
                openidsecret: '', //微信密文
            },
            qrcodeFormRules: {
                mobile: [
                    { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'change'}
                ],
                captcha: [
                    { required: true, message: '请输入验证码', trigger: 'change' }
                ]
            },
            qrcodeState: {
                time: 60,
                loginBtn: false,
                smsSendBtn: false,
                qrcode: '', //二维码地址
                ticket: '', //二维码登录ticker
                showBindPhone: false, //绑定手机号
                qrcodeLoading: true, //二维码加载中
                qrcodeOverdue: false, //二维码过期
            },
            // 手机号登录相关
            phoneFormField: {
                mobile: '',
                captcha: ''
            },
            phoneFormRules: {
                mobile: [
                    { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'change'}
                ],
                captcha: [
                    { required: true, message: '请输入验证码', trigger: 'change' }
                ]
            },
            phoneState: {
                time: 60,
                loginBtn: false,
                smsSendBtn: false,
            }
        }
    },
    created() {
        // 登录状态还在
        if(this.$store.getters['user/isLogin']) {
            this.$store.dispatch('user/getUserinfo').then(() => {
                this.$router.replace({ name: 'home' });
            })
        }
        this.getQrcode();
        this.registerPromoter = this.$route.query.promoter || '';
    },
    destroyed() {
		// 取消退出监听事件
		this.qrcodeState.qrcodeOverdue = true;
	},
    methods: {
        // 登陆切换
        tabChange(tabName) {
            this.tabShow = tabName;
            if(tabName == 'weixin') {
                this.qrcodePolling();
            }
        },
        // 获取二维码图片
        getQrcode() {
            const {qrcodeState, qrcodePolling} = this;
            qrcodeState.qrcodeLoading = true;
            qrcodeState.qrcodeOverdue = false;
            qrcodeState.qrcode = '';
            this.$api.creationLoginQrcode().then(res => {
                if(res.code) {
                    qrcodeState.qrcode = res.data.qrcode_url;
                    qrcodeState.ticket = res.data.ticket;
                    qrcodeState.qrcodeLoading = false;
                    // 开启轮询
                    qrcodePolling();
                    setTimeout(() => {
                        qrcodeState.qrcodeOverdue = true;
                    }, res.data.expire_seconds * 1000)
                }
            })
        },
        // 二维码轮询扫码状态
        qrcodePolling() {
            const { qrcodeState,qrcodeFormField, qrcodePolling, qrcodeLogin } = this;
            // 判断是否过期,或轮询
            if (qrcodeState.qrcodeOverdue || qrcodeState.ticket == '' || this.tabShow != 'weixin') return;
            this.$api.openidSecret({
                ticket: qrcodeState.ticket
            }).then(res => {
                if(res.status) {
                    qrcodeFormField.openidsecret = res.data.openidsecret;
                    // 二维码登录
                    qrcodeLogin();
                }else{
                    setTimeout(function () {
                        qrcodePolling();
                    }, 100);
                }
            })
        },
        // 二维码绑定获取验证码
        qrcodeCaptchaGet(e) {
            e.preventDefault();
            const { qrcodeState, qrcodeFormField } = this;
            this.$refs.qrcodeForm.validateField(['mobile'], err => {
                if (!err) {
                    qrcodeState.smsSendBtn = true;
                    const interval = window.setInterval(() => {
                        if (qrcodeState.time-- <= 0) {
                            qrcodeState.time = 60
                            qrcodeState.smsSendBtn = false
                            window.clearInterval(interval)
                        }
                    }, 1000)
                    this.$message.loading({
                        content: '验证码发送中..',
                        duration: 0,
                        key: 'messageKey'
                    });
                    this.$api.sendVerificationCode({
                        phone: qrcodeFormField.mobile
                    }).then(res => {
                        if(res.status) {
                            this.$message.success({
                                content: '验证码发送成功',
                                key: 'messageKey'
                            })
                        }else{
                            this.$message.warn({
                                content: res.msg,
                                key: 'messageKey'
                            })
                            clearInterval(interval);
                            qrcodeState.time = 60;
                            qrcodeState.smsSendBtn = false;
                        }
                    })
                }
            })
        },
        // 二维码绑定登录
        qrcodeSubmit(e) {
            e.preventDefault();
            const { qrcodeState, qrcodeFormField } = this;
            // 按钮状态修改
            qrcodeState.loginBtn = true;
            this.$refs.qrcodeForm.validate(ispass => {
                if(ispass) {
                    // 验证通过
                    this.$api.mobilelogin({
                        mobile: qrcodeFormField.mobile,
                        captcha: qrcodeFormField.captcha,
                        openidsecret: qrcodeFormField.openidsecret,
                        promoter: this.registerPromoter
                    }).then(res => {
                        if(res.status) {
                            this.$store.commit('user/setToken', res.data.token);
                            // 获取用户信息
                            this.$store.dispatch('user/getUserinfo').then((res) => {
                                // 登录成功
                                this.$router.replace({ name: 'home' });
                                setTimeout(() => {
                                    this.$message.info({
                                        content: `${res.xingming || res.mobile}，欢迎回来`,
                                    });
                                }, 1000)
                            }).finally(() => {
                                qrcodeState.loginBtn = false
                            })
                        }else{
                            this.$message.warn({
                                content: res.msg,
                                key: 'messageKey'
                            })
                            qrcodeState.loginBtn = false
                        }
                    })
                }else{
                    setTimeout(() => {
                        state.loginBtn = false
                    }, 600)
                }
            })
        },
        // 二维码登录
        qrcodeLogin() {
            const { qrcodeState, qrcodeFormField } = this;
            this.$api.loginQrcode({
                openidsecret: qrcodeFormField.openidsecret
            }).then(res => {
                if(res.status) {
                    this.$store.commit('user/setToken', res.data.token);
                    // 获取用户信息
                    this.$store.dispatch('user/getUserinfo').then((res) => {
                        // 登录成功
                        this.$router.replace({ name: 'home' });
                        setTimeout(() => {
                            this.$message.info({
                                content: `${res.xingming || res.mobile}，欢迎回来`,
                            });
                        }, 1000)
                    }).finally(() => {
                        qrcodeState.loginBtn = false
                    })
                }else{
                    qrcodeState.showBindPhone = true;
                }
            })
        },
        // 手机号登录获取验证码
        phoneCaptchaGet (e) {
            e.preventDefault();
            const { phoneState, phoneFormField } = this;
            this.$refs.phoneForm.validateField(['mobile'], err => {
                if (!err) {
                    phoneState.smsSendBtn = true;
                    const interval = window.setInterval(() => {
                        if (phoneState.time-- <= 0) {
                            phoneState.time = 60
                            phoneState.smsSendBtn = false
                            window.clearInterval(interval)
                        }
                    }, 1000)
                    this.$message.loading({
                        content: '验证码发送中..',
                        duration: 0,
                        key: 'messageKey'
                    });
                    this.$api.sendVerificationCode({
                        phone: phoneFormField.mobile
                    }).then(res => {
                        if(res.status) {
                            this.$message.success({
                                content: '验证码发送成功',
                                key: 'messageKey'
                            })
                        }else{
                            this.$message.warn({
                                content: res.msg,
                                key: 'messageKey'
                            })
                            clearInterval(interval);
                            phoneState.time = 60;
                            phoneState.smsSendBtn = false;
                        }
                    })
                }
            })
        },
        // 手机号登录表单提交
        phoneSubmit(e) {
            e.preventDefault();
            const { phoneState, phoneFormField } = this;
            // 按钮状态修改
            phoneState.loginBtn = true;
            this.$refs.phoneForm.validate(ispass => {
                if(ispass) {
                    // 验证通过
                    this.$api.mobilelogin({
                        mobile: phoneFormField.mobile,
                        captcha: phoneFormField.captcha,
                        promoter: this.registerPromoter
                    }).then(res => {
                        if(res.status) {
                            this.$store.commit('user/setToken', res.data.token);
                            // 获取用户信息
                            this.$store.dispatch('user/getUserinfo').then((res) => {
                                // 登录成功
                                this.$router.replace({ name: 'home' });
                                setTimeout(() => {
                                    this.$message.info({
                                        content: `${res.xingming || res.mobile}，欢迎回来`,
                                    });
                                }, 1000)
                            }).finally(() => {
                                phoneState.loginBtn = false
                            })
                        }else{
                            this.$message.warn({
                                content: res.msg,
                                key: 'messageKey'
                            })
                            phoneState.loginBtn = false
                        }
                    })
                }else{
                    setTimeout(() => {
                        state.loginBtn = false
                    }, 600)
                }
            })
        }
    }

};
</script>

<style lang="less" scoped>
.form-box{
    background-color: #fff;
    width: 380px;
    margin: 0 auto;
    border-radius: 10px;
    &__tab{
        line-height: 60px;
        font-size: 18px;
        span{
            display: inline-block;
            width: 50%;
            text-align: center;
            background-color: #e6e6e6;
            &:hover{
                cursor: pointer;
            }
            &:first-child{
                border-top-left-radius: 10px;
            }
            &:last-child{
                border-top-right-radius: 10px;
            }
            &.active{
                background-color: #fff;
            }
        }
    }
    &__weixin{
        padding: 20px;
    }
    &__phone{
        padding: 40px 20px 20px;
        .btn-getcode {
            display: block;
            width: 100%;
            height: 40px;
        }
        .btn-submit {
            padding: 0 15px;
            font-size: 16px;
            height: 40px;
            width: 100%;
        }
    }
}
// 二维码
.qrcode-view{
    text-align: center;
    &__img{
        position: relative;
        width: 180px;
        height: 180px;
        margin: 0 auto;
        img{
            display: block;
            width: 100%;
        }
        .spin{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .overdue{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 60px 0 0;
            box-sizing: border-box;
            text-align: center;
            font-size: 16px;
            color: #111;
            background-color: hsla(0, 0%, 100%, .8);
            p{
                margin-bottom: 5px;
            }
        }
    }

}
// 二维码绑定手机号
.qrcode-form{
    &__tip{
        text-align: center;
        color: #C62333;
        padding: 0 0 5px;
    }
    &__getcode {
        display: block;
        width: 100%;
        height: 40px;
    }
    &__btnsubmit {
        padding: 0 15px;
        font-size: 16px;
        height: 40px;
        width: 100%;
    }
}
</style>
